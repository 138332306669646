import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom'
import Header from "./Header";

const ContactComplete = (props) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="home">
      <Header />
      <section className="press-wrapper">
        <div className="container">
          <h2 className="pg2-title-contact">Contact</h2>
          <div className="press-contents">
            <p className="press-contents-title-contact-complete">Thank you!</p>
          </div>
        </div>
      </section>

    </div>
  )
};

export default ContactComplete;
