import React, { useState, useEffect } from "react";
import testImage from '../assets/news-release.png'
import { Link, useLocation } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';
import Header from "./Header";

const Home = (props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="home">
      <Header />
      <div className="home-image">
        <img src={require('../assets/top-resize-2.png')} />
      </div>

      <div className="home-image-sp">
        <img src={require('../assets/top-sp.png')} />
      </div>

      <section className="press-wrapper">
        <div className="container">
          <h2 className="pg2-title" id="press">Information</h2>
          <p className="press-notice">Information</p>
          <div className="press-contents">
            <p className="press-contents-title">ワンダーコムズDXソリューション、リリースのお知らせ</p>
            <p className="press-date">2021-1-27</p>
            <div className="pg2-more">
              <Link to="/news" className="press-btn">もっと読む</Link>
            </div>
          </div>
        </div>
      </section>

      <section className="service-wrapper">
        <div className="service-container">
          <h2 className="pg2-title">サービス</h2>
          <div className="items">
            <div className="item">
              <div className="item-text-contents">
                <p className="service-title">デジタルトランスフォーメーション事業</p>
              </div>
              <div className="service-img">
                <img src={require('../assets/service001.png')} alt="development" />
              </div>
              <div className="item-text-contents">
                <p className="service-title">クラウド・サーバーレスなど最先端の技術による<br />お客様のデジタル化をサポートします</p>
              </div>
              <div className="item-text-contents">
                <p className="service-title">
                  <HashLink smooth to="/service#second" className="press-btn-blue">サービスを見る</HashLink>
                </p>
              </div>

            </div>
          </div>
        </div>
      </section>
      <section className="about-us-wrapper">
        <div className="container">
          <div className="items">
            <div className="item">
              <h2 className="about-us-title">Company</h2>
              <p className="about-us-text">会社情報など働く環境についての<br />ご紹介はこちらから</p>
              <div className="about-us-btn">
                <Link to="/company">会社概要へ</Link>
              </div>
            </div>
            <div className="item">
              <h2 className="about-us-title">Information</h2>
              <p className="about-us-text">会社や運営するサービスの<br />情報はこちらから</p>
              <div className="about-us-btn">
                <Link to="/news">お知らせへ</Link>
              </div>
            </div>
            <div className="item">
              <h2 className="about-us-title">Contact</h2>
              <p className="about-us-text">サービスに関するご相談<br />お問い合わせはこちらから</p>
              <div className="about-us-btn">
                <Link to="/contact">お問い合わせへ</Link>
              </div>
            </div>

          </div>
        </div>
      </section>

    </div>
  )
};

export default Home;
