import React, { useState, useEffect } from "react";
import testImage from "../assets/news-release.png";
import { Link, useLocation } from 'react-router-dom'
import Header from "./Header";

const News = (props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="home">
      <Header />
      <section className="press-wrapper">
        <div className="container">
          <h2 className="pg2-title" id="press">Information</h2>
          <p className="press-notice">Information</p>
        </div>
      </section>

    </div>
  )
};

export default News;
