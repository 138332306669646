import React, { useState, useEffect } from "react";
import firebase from "../Firebase";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom'
import Header from "./Header";

const Contact = (props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const handleOnSubmit = async (values) => {
    console.log(values)
    /*
const db = firebase.firestore();
var hostName = document.location.hostname;
if( hostName == "localhost" || hostName == "127.0.0.1" ){
  db.settings({
    host: "localhost:8080",
    ssl: false,
  });
}
*/
    const db = firebase.firestore();
    db.collection("contacts").add({
      companyName: values.companyName,
      email: values.email,
      tel: values.tel,
      content: values.content,
      companyUserName: values.companyUserName,
    })
      .then(function (docRef) {
        console.log("Document written with ID: ", docRef.id);
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
    props.history.push({ pathname: "/contact-complete" });
  }

  return (
    <div className="home">
      <Header />
      <div className="form-top">
        <h2 className="pg2-title-contact">Contact</h2>
        <Formik
          initialValues={{ email: '', tel: '', companyName: '', content: '', companyUserName: '' }}
          onSubmit={(values) => handleOnSubmit(values)}
          validationSchema={Yup.object().shape({
            email: Yup.string().email('The format is not an email address.').required('mail is required.'),
            tel: Yup.string().matches(phoneRegExp, 'Invalid phone number format').required('telephone number is required.'),
            companyName: Yup.string().required('company name is required.'),
          })}
        >
          {
            ({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
              <form onSubmit={handleSubmit} className="Form">
                <div className="Form-Item-Top">
                  <label htmlFor="companyName" className="Form-Item-Label"><span className="Form-Item-Label-Required">required</span>Company</label>
                  <input style={{ fontSize: 16, outline: 0 }} type="companyName" className="Form-Item-Input" placeholder="Wondercoms, INC."
                    name="companyName"
                    id="companyName"
                    value={values.companyName}
                    onChange={handleChange}
                    onBlur={handleBlur} />

                </div>
                <div className="error">
                  {errors.companyName}
                </div>
                <div className="Form-Item">
                  <label htmlFor="companyUserName" className="Form-Item-Label">Name</label>
                  <input style={{ fontSize: 16, outline: 0 }} type="companyUserName" className="Form-Item-Input" placeholder=""
                    name="companyUserName"
                    id="companyUserName"
                    value={values.companyUserName}
                    onChange={handleChange}
                    onBlur={handleBlur} />

                </div>
                <div className="error">
                  {errors.companyUserName}
                </div>
                <div className="Form-Item">
                  <label htmlFor="tel" className="Form-Item-Label"><span className="Form-Item-Label-Required">required</span>Telephone Number</label>
                  <input style={{ fontSize: 16, outline: 0 }} type="tel" className="Form-Item-Input" placeholder="000-0000-0000"
                    name="tel"
                    id="tel"
                    value={values.tel}
                    onChange={handleChange}
                    onBlur={handleBlur} />
                </div>
                <div className="error">
                  {errors.tel}
                </div>
                <div>
                  <div className="Form-Item">
                    <label htmlFor="email" className="Form-Item-Label"><span className="Form-Item-Label-Required">required</span>Email</label>
                    <input style={{ fontSize: 16, outline: 0 }} name="email"
                      id="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="email"
                      className="Form-Item-Input"
                      placeholder="例）example@gmail.com" />
                  </div>
                  <div className="error">
                    {errors.email}
                  </div>
                </div>

                <div className="Form-Item">
                  <label className="Form-Item-Label isMsg" htmlFor="content">Content of inquiry
                    </label>
                  <textarea style={{ fontSize: 16, outline: 0 }} className="Form-Item-Textarea"
                    type="content"
                    placeholder=""
                    name="content"
                    id="content"
                    value={values.content}
                    onChange={handleChange}
                    onBlur={handleBlur} />
                </div>

                <input type="submit" className="Form-Btn" value="Send" />
              </form>
            )
          }
        </Formik>
      </div>
    </div>
  )
};

export default Contact;
