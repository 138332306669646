import React from 'react';
import Header from "./Header";

export default function NotFound() {
  return (
    <div className="home">
      <Header />
      <div className="notfound">
        Not found
        </div>
    </div>
  );
}
