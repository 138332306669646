import React from 'react';
import logo from '../assets/logo_header.png'
import { Link } from 'react-router-dom'

export default ({ name }) => {
  return (
      <header>
        <div className="top-content">
          <div className="links">
            <Link to="/contact">Contact Us</Link>
          </div>
        </div>
        <nav className="header-nav">
          <ul className="main-menu">
            <li className="logo"><img src={logo} alt="ロゴ"/></li>
            <li><Link to="/company">Company Information</Link></li>
            <li><Link to="/news">Information</Link></li>
          </ul>
          <ul className="right-menu">
            <li></li>
            <li></li>
          </ul>
        </nav>
      </header>
  )};