import React from 'react';
import { Link } from 'react-router-dom'

export default ({ name }) => {
  return (
      <footer>
        <div className="footer-bottom">
          <div className="container">
            <ul className="footer-bottom-items">
              <li><Link className="footer-bottom-item" to="/company">Company</Link></li>
              <li><Link className="footer-bottom-item" to="/news">Information</Link></li>
              <li><Link className="footer-bottom-item" to="/contact">Contact</Link></li>
            </ul>
            <p className="copy-right">©︎ WonderComs Inc.</p>
          </div>
        </div>
      </footer>
  )};