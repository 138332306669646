import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom'
import Header from "./Header";

const Company = (props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="home">
      <Header />
      <section className="about-company-wrapper">
        <div className="container">
          <div className="about-company-contents">
            <h2 className="pg2-title">Company information</h2>
            <div className="items">
              <div className="box">
                <div className="item">
                  <p className="about-company-title">Company</p>
                  <p className="about-company-text">Wondercoms, Inc.</p>
                </div>
                <div className="item">
                  <p className="about-company-title">Date of establishment</p>
                  <p className="about-company-text">March 2014</p>
                </div>
                <div className="item">
                  <p className="about-company-title">CEO</p>
                  <p className="about-company-text">Hiroshi Yata</p>
                </div>
              </div>
              <div className="box">
                <div className="item">
                  <p className="about-company-title">Business content</p>
                  <p className="about-company-text"><a href="#">Application development</a></p>
                </div>
                <div className="item">
                  <p className="about-company-title">Contact</p>
                  <p className="about-company-text"><a href="#">Please contact us from here.</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
};

export default Company;
