import React from 'react';
import { Link } from 'react-router-dom'

export default function NotFound() {
  const handleClick = async (e, tel_no) => {
    window.gtag('event', 'select_content', {
      content_type: "tel",
      promotions: [
        {
          id: tel_no,
          name: 'tel',
        }
      ]
    });
  }

  return (
    <div>
      <div className="top-content-dx">
        <div className="links">
          <a href={"tel:" + "05036275657"} onClick={(e) => handleClick(e, 1)}>
            <img src={require('../assets/tel.png')} alt="tel" />050-3627-5657
          </a>
        </div>
      </div>
      <div className="youtube">
        <iframe src="https://www.youtube.com/embed/R59Y1h8ssKg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div className="tel-wrapper">
        <div className="container">
          <p className="tel-head">ご不明な点はお気軽にお問い合わせください。</p>
          <h3 className="tel-number">
            <a href={"tel:" + "05036275657"} onClick={(e) => handleClick(e, 2)} style={{ color: 'white', textDecoration: 'none' }}>
              <img src={require('../assets/tel.png')} alt="tel" />050-3627-5657
              </a>
          </h3>
          <p className="tel-time">受付時間 : 平日9:00〜19:00(12:00〜13:00除く)</p>
        </div>
      </div>
      <div className="service-wrapper-dx-solution">
        <div className="service-container">
          <div className="items">
            <div className="item-left">
              <div className="item-text-contents">
                <p className="service-title-top">弊社DXソリューションの特徴</p>
              </div>
              <div className="item-text-contents">
                <p className="service-title">我々の目的はお客様のビジネスをデジタル化することによるDX企業化です。
                最先端のデジタル技術でお客様の業務のクラウド化、自動化を行いお客様のビジネスプロセスをデジタル化します。
                データファーストであることがDX化の鍵を握ります。お客様視点で必要なサポートを一気通貫で行います。</p>
              </div>
            </div>
            <div className="item-right">
              <div className="service-img">
                <img src={require('../assets/solution.jpg')} alt="development" />
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="service-wrapper-dx-main">
        <div className="service-container">
          <h2 className="pg2-title">ソリューション</h2>
          <div className="items">
            <div className="item">
              <div className="item-text-contents">
                <p className="service-title-top">状況の調査・可視化</p>
              </div>
              <div className="service-img-dx">
                <img src={require('../assets/dx-1.png')} alt="development" />
              </div>
              <div className="item-text-contents">
                <p className="service-title">
                  DX推進指標をベースとした指標を用いて社内システムのデジタル活用度及び技術的負債を可視化・定量化します。
                  その上で、DXのビジネスチャンス・コスト削減計画・得られる顧客体験を精緻に評価し、全関係者が納得できる状態の戦略を策定します。
                  </p>
              </div>
            </div>
            <div className="item">
              <div className="item-text-contents">
                <p className="service-title-top">DXの実行・開発支援</p>
              </div>
              <div className="service-img-dx">
                <img src={require('../assets/dx-2.png')} alt="development" />
              </div>
              <div className="item-text-contents">
                <p className="service-title">
                  各種クラウドシステムやサーバーレス等の先端技術を活用した顧客体験向上のための新規事業・アプリケーション開発はもちろん、
                  コスト削減・デジタル競争力強化のためのクラウド移行やゼロトラストセキュリティの設計、デジタル人材の育成や採用など、
                  企業様の希望を一気通貫でサポートいたします。</p>
              </div>

            </div>
            <div className="item">
              <div className="item-text-contents">
                <p className="service-title-top">最先端技術の採用</p>
              </div>
              <div className="service-img">
                <img src={require('../assets/service001.png')} alt="development" />
              </div>
              <div className="item-text-contents">
                <p className="service-title">
                  サーバーレスシステムやビッグデータ解析システムの構築・運用実績や数々の企業様との協働を通じて蓄えた知見を元に、
                本番稼働のためのプロダクトのコンサルティング・開発支援をいたします。</p>
              </div>
            </div>
            <div className="item">
              <div className="item-text-contents">
                <p className="service-title-top">デジタルマーケティング支援</p>
              </div>
              <div className="service-img-marketing">
                <img src={require('../assets/marketing.png')} alt="development" />
              </div>
              <div className="item-text-contents">
                <p className="service-title">
                  顧客の体験をトータルでアップデートするためには商品やサービスの提供方法や接点開発も重要です。
                  弊社はマーケティング戦略の立案からデジタル広告の運用、PR、SEO、コミュニティ活性化、
                  ソーシャルメディア活用などの大手企業のデジタルマーケティング支援も行っています。</p>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="service-company-wrapper">
        <div className="service-container">
          <h2 className="pg2-title">過去実績</h2>
          <div className="items">
            <div className="item">
              <div className="service-img">
                <img src={require('../assets/og.png')} />
              </div>
            </div>
            <div className="item">
              <div className="service-img">
                <img src={require('../assets/company_c2.png')} />
              </div>
            </div>
            <div className="item">
              <div className="service-img">
                <img src={require('../assets/company_r.png')} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
