import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

const Scroller = (props) => {
  const [state, setState] = useState({
    currentPosition: 0,
    showFadeIn: false,
  });

  const route_name = window.location.pathname.split("/")[1];

  const fadeIn = () => {
    setState({
      ...state,
      showFadeIn: true,
    });
  }
  const fadeOut = () => {
    setState({
      ...state,
      showFadeIn: false,
    });
  }

  const scrollTop = () => {
    return Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop);
  }

  const watchCurrentPosition = () => {
    if (scrollTop() > 300) {
      fadeIn()
    }

    if (scrollTop() < 100) {
      fadeOut()
    }
  }

  const handleClick = async (e, tel_no) => {
    window.gtag('event', 'select_content', {
      content_type: "tel",
      promotions: [
        {
          id: tel_no,
          name: 'tel',
        }
      ]
    });
  }

  useEffect(() => {
    window.addEventListener('scroll', event => watchCurrentPosition(), true);
  }, []);
  return (
    <div>
      {(state.showFadeIn) && (
        <div className="an2-fadeup">
          <p className="fadeup-text">Contact Us</p>
          <a href={"tel:" + '05036275657'} onClick={(e) => handleClick(e, 3)} className="fadeup-press-btn-blue">050-3627-5657</a>
        </div>
      )}
    </div>
  )
};

export default Scroller;
