import React, { useState, useEffect } from "react";
import { Router, Route, Switch, useHistory } from "react-router-dom";
import './App.css';
import Home from "./components/Home";
import { createBrowserHistory } from "history";
import Footer from "./components/Footer";
import News from "./components/News";
import Contact from "./components/Contact";
import Service from "./components/Service";
import Company from "./components/Company";
import ContactComplete from "./components/ContactComplete";
import NotFound from "./components/NotFound";
import Scroller from "./components/Scroller";
import Dx from "./components/Dx";

function InnerComponent() {
  const { listen } = useHistory()
  const trackingId = process.env.REACT_APP_MEASUREMENT_ID

  useEffect(() => {
    const unlisten = listen((location) => {
      if (!window.gtag) return
      if (!trackingId) {
        console.log(
          'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.'
        )
        return
      }
      window.gtag('config', trackingId, { page_path: location.pathname })
    })
    return unlisten
  }, [trackingId, listen])

  return (
    <div />
  );
}

const App = (props) => {
  return (
    <Router history={createBrowserHistory()}>
      <InnerComponent />
      <div id="app">
        <Scroller />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/company" exact component={Company} />
          <Route path="/dx" exact component={Dx} />
          <Route path="/news" exact component={News} />
          <Route path="/service" exact component={Service} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/contact-complete" exact component={ContactComplete} />
          <Route path="*" component={NotFound} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
