import React, { useEffect } from "react";
import testImage from "../assets/news-release.png";
import { Link, useLocation } from 'react-router-dom'
import Header from "./Header";

const Service = (props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="home">
      <Header />
      <section className="press-wrapper" id="first">
        <div className="container">
          <h2 className="pg2-title">デジタルトランスフォーメーション事業</h2>
          <div className="press-contents">
            <div className="press-contents-title-contact-complete">クラウド・サーバーレスなど最先端の技術により<br />お客様のデジタル化をサポートします</div>
            <div className="image">
              <div>
                <img src={require('../assets/service.png')} alt="" />
              </div>
            </div>
            <div className="service-content">
              <div className="service-confirm-content">
                <div className="service-confirm-top">
                  お客様のデジタル企業化を促進するため、最新のデジタルマーケティング支援、AWS、GCP、Firebaseなどの各クラウドプラットフォームに対応し最適な自動化、
                  サーバーレス化に対応、導入します。
                  </div>
              </div>
            </div>
            <div className="pg2-more">
              <Link className="press-btn-blue" to="/dx">サービスサイトへ</Link>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
};

export default Service;
